
import Logo from "@/components/Logo/index.vue"
import { Component, Vue } from 'vue-property-decorator'
import { Form as ElForm, Input } from 'element-ui'
import handleError from "@/utils/handleError"
import { Authentication } from "@/network/api"

@Component({
  name: 'PasswordReset',
  components: {
    Logo
  }
})
export default class extends Vue {
  private regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(.{8,})$/
  code = "";
  done = false;

  private validateEmail = (rule: any, value: string, callback: Function) => {
    if (value.length < 1) {
      callback(new Error('Email is required'))
    }
    else {
      callback()
    }
  }

  private validatePassword = (rule: any, value: string, callback: Function) => {
    const isPassword = this.regexPassword.test(value)
    if (value.length < 1) {
      callback(new Error('Password is required'))
    }
    else if (!isPassword) {
      callback(new Error('Password must have at least 8 characters, 1 uppercase character, 1 lowercase character, 1 symbol and 1 number.'))
    } else {
      (this.$refs.resetForm as ElForm).validateField('passwordConfirm')
      callback()
    }
  }

  private validatePasswordConfirm = (rule: any, value: string, callback: Function) => {
    if (this.resetForm.password !== this.resetForm.passwordConfirm) {
      callback(new Error('The passwords do not match'))
    } else {
      callback()
    }
  }

  private forgotForm = {
    email: '',
  }

  private resetForm = {
    email: '',
    password: '',
    passwordConfirm: '',
  }

  private forgotRules = {
    email: [{ validator: this.validateEmail, trigger: 'blur' }],
  }

  private resetRules = {
    email: [{ validator: this.validateEmail, trigger: 'blur' }],
    password: [{ validator: this.validatePassword, regex: this.regexPassword, trigger: 'blur' }],
    passwordConfirm: [{ validator: this.validatePasswordConfirm, trigger: 'blur' }]
  }

  private passwordType = 'password'
  private passwordConfirmType = 'password'
  private loading = false
  private showDialog = false

  created() {
    let queries = this.$route.query as any;
    console.log(queries)
    if (queries.code != null) {
      this.code = unescape(queries.code) as string;
    }
  }

  mounted() {
    if (this.resetForm.email === '') {
      (this.$refs.email as Input).focus()
    } else if (this.resetForm.password === '') {
      (this.$refs.password as Input).focus()
    }
  }

  private showPwd(isConfirm: boolean) {
    if(!isConfirm) {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
    }
    else {
      if (this.passwordConfirmType === 'password') {
        this.passwordConfirmType = ''
      } else {
        this.passwordConfirmType = 'password'
      }
    }
    this.$nextTick(() => {
      if(!isConfirm) {
        (this.$refs.password as Input).focus()
      }
      else {
        (this.$refs.passwordConfirm as Input).focus()
      }
    })
  }

  private async handlePasswordReset() {
    (this.$refs.resetForm as ElForm).validate(async(valid: boolean) => {
      if(valid) {
        this.loading = true

        if(!this.code) {
          await Authentication.apiAccountForgotPasswordPost(this.forgotForm.email).then((res) => {
            this.loading = false
            this.$message({showClose: true, type: 'success', duration: 2000, message: 'Password reset link sent!'})
            this.done = true
          })
          .catch((error) => {
            this.loading = false
            handleError(error, true)
          })
        } else {
          await Authentication.apiAccountResetForgotPasswordPost(this.resetForm.email, this.code, this.resetForm.password).then((res) => {
            this.loading = false
            this.$message({showClose: true, type: 'success', duration: 2000, message: 'Password reset successful!'})
            this.$router.push({name: 'Login', query: { email: this.resetForm.email}})
          })
          .catch((error) => {
            this.loading = false
            handleError(error, true)
          })
        }
        
      }
    })
  }
}
